import React from 'react';
import Image from 'next/image';
import AppButton from '@/app/components/atomic/molecules/AppButton';
import {IAppliedFilter} from "@/app/models/auctor";
import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";
import TableBlock from "@/app/components/auctor/tool/TableBlock";
import dynamic from "next/dynamic";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'), {
    ssr: false,
});

const GaugeBlock = dynamic(() => import('@/app/components/auctor/tool/GaugeBlock'), {
    ssr: false,
    loading: () => <GaugeBlockSkeleton />
});

interface ISectionBlockProps {
    block: any;
    expressionContext?: string;
    appliedFilters: IAppliedFilter[];
    toolData: any;
}

export default function SectionBlock({block, expressionContext, appliedFilters, toolData}: ISectionBlockProps) {
    const imageSizeToClass = (size: string) => {
        switch (size) {
            case 'sm':
                return 'w-20 md:w-18 h-14'
            case 'md':
                return 'w-32 md:w-28 h-20'
            case 'lg':
                return 'w-48 md:w-40 h-32'
            case 'xl':
                return 'w-64 md:w-56 h-48'
            default:
                return 'w-32 md:w-28 h-20'
        }
    }

    return (
        <div
            className={`col-span-${block.col_span} flex justify-center items-center`}
        >
            {block.type === 'text' && (
                <span
                    className={`text-${block.text_font_size} font-${block.text_font_weight}`}
                    style={{color: block.text_color}}
                >
                    {block.text_content}
                </span>
            )}

            {block.type === 'image' && (
                <div className={`relative object-contain ${imageSizeToClass(block.image_size)}`}>
                    <Image
                        src={block.image_url}
                        alt={block.image_alt ?? ''}
                        fill
                        className="object-contain"
                        sizes="(max-width: 768px) 40vw, (max-width: 1200px) 30vw, 10vw"
                    />
                </div>
            )}

            {block.type === 'expression' && (
                <div
                    className="w-full"
                    dangerouslySetInnerHTML={{
                        __html: evaluateExpressionWithFiltersAndRowData(block.expression, toolData, appliedFilters, expressionContext) ?? '',
                    }}
                />
            )}

            {block.type === 'button' && (
                <AppButton
                    isLink
                    href={block.button_target_url}
                    size={block.button_size ?? "block"}
                    theme={block.button_theme ?? 'orangePrimary'}
                >
                    {block.button_label}
                    {block.button_theme === 'whitePrimaryArrow' && (
                        <AppIcon
                            iconClassName={'fa-arrow-right'}
                            type={'regular'}
                            size={'lg'}
                        />
                    )}
                </AppButton>
            )}

            {block.type === 'gauge' && (
                <GaugeBlock
                    block={block}
                    appliedFilters={appliedFilters}
                    toolData={toolData}
                    expressionContext={expressionContext}
                />
            )}

            {block.type === 'table' && (
                <TableBlock
                    block={block}
                    appliedFilters={appliedFilters}
                    toolData={toolData}
                    expressionContext={expressionContext}
                />
            )}
        </div>
    );
}

function GaugeBlockSkeleton() {
    return (
        <div className={'flex flex-col justify-center items-center gap-2 relative'}>
            <div className={'w-[140px] h-[140px] bg-gray-400 rounded-full animate-pulse'} />
        </div>
    )
}


